<template>
    <v-container pa-0>
        <v-row class="ma-0 heading">
            <v-col class="pa-0 d-flex align-center">
                <div class="text">{{$attrs.section.heading}}</div>
            </v-col>
            <v-col class="d-flex align-center justify-end pa-0">
                <div
                v-if="$attrs.section.value === 'creativeTeam' && $attrs.accessLevel === 'high'"
                >
                    <v-btn class="btn-purple" @click="$emit('add-member')">
                        Add Member
                    </v-btn>
                </div>
                <div v-if="$attrs.section.value === 'addMember'"
                    class="d-flex" >
                    <base-input
                        class="search-input mr-4"
                        type="text"
                        solo
                        dense
                        flat
                        :hide-details="true"
                        prepend-inner-icon="search"
                        placeholder="Search"
                        v-model="searchVal"
                        clearable
                        @click:clear="searchVal === ''"
                    >
                    </base-input>
                    <v-select
                        :items="getSkillsets()"
                        label="Skill Set"
                        solo
                        item-value="id"
                        item-text="name"
                        :hide-details="true"
                        v-model="skillIds"
                        multiple=""
                        @change="$emit('select-skillset', skillIds)"
                        :menu-props="{'content-class' : 'skillset-dropdown'}"
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text caption"
                            >(+{{ skillsetIds.length - 1 }} others)</span>
                        </template>
                    </v-select>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseInput from '@/components/common/BaseInput';

export default {
  components: {
    BaseInput,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    skillsetIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('profile', ['skillsets']),
  },
  data() {
    return ({
      searchVal: this.search,
      skillIds: this.skillsetIds,
    });
  },
  methods: {
    ...mapActions(['setModal']),
    getSkillsets() {
      return (this.skillsets);
    },
  },
  watch: {
    searchVal(newValue) {
      this.$emit('search', newValue);
    },
    search(newValue) {
      this.searchVal = newValue;
    },
    skillsetIds(newValue) {
      this.skillIds = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
    .heading {
      padding: 10px 45px;
      .text {
        font-size: 24px;
        letter-spacing: -0.6px;
        color: $neutral1;
      }
    }
    // ::v-deep .input-field {
    //     height: inherit;
    // }
    // ::v-deep .v-input__control {
    //     // min-height: inherit !important;
    // }
    ::v-deep .v-input__slot {
        border-radius: 6px !important;
        box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
        background-color: $neutral6 !important;
        input {
            font-family: $fontFamily1;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.25;
        }
    }
    .search-input {
        font-family: $fontFamily1 !important;
        ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
            min-height: 36px !important;
        }
    }
    ::v-deep {
      .input-field {
        height: auto;
      }
      .v-select .v-input__control {
        width: 255px;
      }
      .v-input__control {
        font-family: $fontFamily1;
          min-height: 38px !important;
            > .v-input__slot {
                border: solid 1px #b0b0b0 !important;
                border-radius: 0 !important;
                box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
                min-height: 38px !important;
            }
      }
      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
        > .v-input__control > .v-input__slot {
          min-height: 38px !important;
          .v-chip.v-size--default {
            height: auto;
          }
      }
}

</style>
