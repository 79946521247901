<template>
  <div class="h-100" ref="team-list">
    <base-list
    :headers="headers"
    :items="items"
    :fetching="loading"
    > </base-list>
    <v-dialog v-model="dialog" max-width="800">
      <v-card class="modal-card pa-4" flat>
        <v-card-title class="black--text">
          Deleting a user from Creative Team removes creative from all future sweeps. Click
          on Confirm to continue.
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primaryGray1" @click="dialog = false" class="py-0">
            Cancel
          </v-btn>
          <v-btn
            text
            color="info"
            type="submit"
            class="pa-0 ml-2"
            @click="deleteItem(selectedItem)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div :ref="'details-popup'" v-show="showDetails" class="details-block">
      <user-details-card
        :user="selectedMember"
        @closePopup="showDetails = false"
        :showTags="showTags(selectedMember)"
      >
      </user-details-card>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { generateUrl, getOptimizedS3ImageURL } from '@/helpers';
import BaseList from '@/components/admin/common/BaseList';
import StatusIcon from '@/components/projects/ProjectTeam/CheckMarkCircle';
import MemberName from '@/components/projects/ProjectTeam/MemberName';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import UserDetailsCard from './UserDetailsCard';

export default {
  components: {
    UserDetailsCard,
    BaseList,
  },
  computed: {
    ...mapGetters('project', { projectDetailsTeam: 'projectTeam' }),
    ...mapGetters('dashboard', { dashboardDetailsTeam: 'projectTeam' }),
    headers() {
      const $this = this;
      const items = [
        $this.statusColumn(),
        $this.nameColumn(),
        $this.skillColumn(),
        $this.websiteColumn(),
      ];
      if ($this.$attrs.accessLevel === 'high') {
        items.push($this.deleteColumn());
      }
      return items;
    },
    memberInitials() {
      return (member) => {
        let { name } = member;
        name = name.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    creativeTeam() {
      const projectTeamObj = this.$attrs.accessLevel === 'low' ? this.dashboardDetailsTeam : this.projectDetailsTeam;
      return [
        ...projectTeamObj.producersTeam,
        ...projectTeamObj.executiveTeam,
        ...projectTeamObj.creativeTeam,
        ...projectTeamObj.editorTeam,
      ];
    },
    items() {
      const ordering = {
        'Production Manager': 1,
        'Content Producer': 2,
        Editor: 3,
      };
      return _.chain(this.creativeTeam)
        .map((user) => ({
          id: user.user.id,
          status: this.status(user.accepted),
          name: `${user.user.firstName} ${user.user.lastName}`,
          skillset: this.type(user.user),
          order: ordering[this.type(user.user)] || 100,
          interests: _.get(user.user.creativeProfile, 'interests', []).map((x) => x.name),
          camera: _.get(user.user.creativeProfile, 'cameraEquipments', []).map((x) => x.name),
          lens: _.get(user.user.creativeProfile, 'lensEquipments', []).map((x) => x.name),
          accessories: _.get(user.user.creativeProfile, 'accessories', []).map((x) => x.name),
          copyInterests: _.get(user.user.creativeProfile, 'writerInterests', []).map((x) => x.name),
          styles: _.get(user.user.creativeProfile, 'styles', []).map((x) => x.name),
          // interests: _.get(user.user.creativeProfile)
          // eslint-disable-next-line global-require
          profilePic: user.user.profilePic,
          portfolio: user.user.producerProfile
            ? _.get(user.user.producerProfile, 'website', '')
            : _.get(user.user.creativeProfile, 'portfolio', ''),
        }))
        .sort((a, b) => a.order - b.order)
        .value();
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selectedItem: {},
      selectedMember: {},
      showDetails: false,
    };
  },
  methods: {
    ...mapActions('project', ['removeCreativeFromTeam', 'getProjectTeam']),
    getOptimizedS3ImageURL,
    statusColumn() {
      return {
        field: 'status',
        headerName: '',
        width: 38,
        cellClass: 'd-flex align-center',
        cellRendererFramework: StatusIcon,
      };
    },
    nameColumn() {
      const $this = this;
      return {
        field: 'name',
        headerName: 'Name',
        cellClass: 'd-flex align-center notranslate',
        cellRendererFramework: MemberName,
        cellRendererParams: {
          clicked(params) {
            $this.showPopup(params.data);
            return false;
          },
        },
      };
    },
    websiteColumn() {
      return {
        field: 'portfolio',
        headerName: 'Website',
        headerClass: 'center-align',
        cellClass: 'd-flex align-center justify-center',
        cellRenderer: (params) => `<span v-if="item.portfolio" class="d-flex flex-column">
          <a href=${this.portfolioUrl(params.data.portfolio)} target="_blank">
            <span class="mdi mdi-open-in-new text-purple" style="font-size: 20px;"></span>
          </a>
        </span>`,
      };
    },
    deleteColumn() {
      const $this = this;
      return {
        field: '',
        width: 20,
        cellRendererFramework: BtnCellRenderer,
        cellStyle: { display: 'flex', alignItems: 'center' },
        cellRendererParams: (params) => {
          if (
            params.data.skillset !== 'Content Producer'
            && params.data.skillset !== 'Production Manager'
            && params.data.skillset !== 'Editor'
          ) {
            return {
              image: 'trash',
              clicked() {
                $this.openDeleteConfirm(params.data);
                return false;
              },
            };
          }
          return false;
        },
      };
    },
    skillColumn() {
      return {
        field: 'skillset',
        headerName: 'Creative Type',
        cellClass: 'd-flex align-center',
      };
    },
    status(status) {
      let obj = {};

      switch (status) {
        case '1':
          obj = {
            icon: 'mdi-check-circle',
            color: '#02e648',
            text: 'Accepted',
          };
          break;

        case '2':
          obj = {
            icon: 'mdi-close-circle',
            color: '#ff0808',
            text: 'Declined',
          };
          break;

        default:
          obj = {
            icon: 'mdi-clock-outline',
            color: 'black',
            text: 'Pending',
          };
          break;
      }
      return obj;
    },
    openDeleteConfirm(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    deleteItem(item) {
      this.removeCreativeFromTeam({
        projectId: this.$route.params.projectId,
        creativeId: item.id,
      });
      this.dialog = false;
    },
    showPopup(item) {
      this.showDetails = true;
      this.selectedMember = item;
    },
    showTags(user) {
      if (user.skillset) {
        const skills = user.skillset.split(',');
        if (skills.length > 1) {
          return true;
        }
        return skills[0] !== 'Graphic Designer';
      }
      return false;
    },
    skillsetNames(skillsets) {
      return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    portfolioUrl(link) {
      return generateUrl(link);
    },
    type(user) {
      switch (user.role.name) {
        case 'producer':
          return 'Content Producer';
        case 'executive':
          return 'Production Manager';
        case 'editor':
          return 'Editor';
        default:
          return this.skillsetNames(_.get(user.creativeProfile, 'skillsets', []));
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.getProjectTeam(this.$route.params.projectId);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 45px;
}

.details-block {
  position: absolute;
  width: 300px;
  z-index: 11;
  top: 160px;
  left: 250px;
}
</style>
