<template>
  <v-tooltip bottom class="d-flex flex-column status">
    <template v-slot:activator="{ on }">
      <v-icon
      v-on="on"
      size="25"
      :color="params.value.color"
      :style="`transform: ${params.value.icon === 'mdi-clock-outline' && 'rotate(240deg)'}`">
      {{ params.value.icon }} </v-icon>
    </template>
    <span class="status-text pa-1"> {{ params.value.text }} </span>
  </v-tooltip>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'CheckMark',
});
</script>
