<template>
    <v-container pa-0 class="project-team" h-100>
        <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
                <ProjectTeamHeader
                  :section="currentSection"
                  @search="searchHandler"
                  @add-member="showSection('addMember')"
                  :accessLevel="accessLevel"
                  @select-skillset="skillsetSelected"
                  :search="search"
                  :skillsetIds="skillsetIds"
                />
                <div class="project-team-body">
                  <ProjectTeamList
                    v-if="currentSection.value === 'creativeTeam'"
                    :accessLevel="accessLevel"
                  />
                  <AddMember v-if="currentSection.value === 'addMember'"
                    :search="search"
                    @remove-filters="removeFilters()"
                    @selected-members="updateSelectedMembers"
                    :skillsetIds="skillsetIds"
                  />
                </div>
                <v-divider />
                  <ProjectTeamFooter
                    :section="currentSection"
                    @add-members="addMembers"
                    :selectedCount="selected.length"
                    @show-creativeTeam="showSection('creativeTeam')"
                  />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ProjectTeamHeader from '@/components/projects/ProjectTeam/ProjectTeamHeader';
import ProjectTeamList from '@/components/projects/ProjectTeam/ProjectTeamList';
import AddMember from '@/components/projects/ProjectTeam/AddMember';
import ProjectTeamFooter from '@/components/projects/ProjectTeam/ProjectTeamFooter';

export default {
  components: {
    ProjectTeamHeader,
    ProjectTeamList,
    AddMember,
    ProjectTeamFooter,
  },
  computed: {
    ...mapGetters('dashboard', { dashboardProject: 'project' }),
    ...mapGetters('project', ['currentProject']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    accessLevel() {
      if (this.currentProject.status === '2') {
        return 'medium';
      }
      switch (this.role) {
        case 'admin':
        case 'producer':
        case 'executive':
          return 'high';
        case 'creative':
          return 'medium';
        default:
          return 'low';
      }
    },
  },
  data() {
    return ({
      currentSection: {
        heading: 'Team',
        value: 'creativeTeam',
      },
      search: '',
      selected: [],
      skillsetIds: [],
    });
  },
  methods: {
    ...mapActions('profile', ['getCreativeOptions']),
    ...mapActions('project', ['addProjectMembers', 'getProjectTeam', 'getProject']),
    ...mapActions('dashboard', ['getDashboardDetails']),
    showSection(value) {
      this.currentSection.value = value;
      switch (value) {
        case 'creativeTeam':
          this.currentSection.heading = 'Team';
          break;
        case 'addMember':
          this.currentSection.heading = 'Add Team Member';
          break;
        default:
          this.currentSection.heading = '';
          break;
      }
    },
    searchHandler(search) {
      this.search = search;
    },
    addMembers() {
      const creativeIds = _.map(this.selected, 'id');
      this.addProjectMembers({ projectId: this.$route.params.projectId, creativeIds });
    },
    updateSelectedMembers(selected) {
      this.selected = selected;
    },
    skillsetSelected(selected) {
      this.skillsetIds = selected;
    },
    removeFilters() {
      this.search = '';
      this.skillsetIds = [];
    },
  },
  mounted() {
    if (this.accessLevel === 'high') {
      this.getCreativeOptions();
    }
    if (this.accessLevel === 'low' && !this.dashboardProject.id) {
      this.getDashboardDetails();
    }
    if (!this.currentProject.id || this.currentProject.id !== this.$route.params.projectId) {
      this.getProjectTeam(this.$route.params.projectId);
      const project = this.getProject(this.$route.params.projectId);
      if (!project) {
        this.$router.push({ path: '/dashboard' });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .project-team {
    background: $neutral6;
    .project-team-body {
      padding: 20px 45px;
      height: calc(100vh - 200px);
      overflow: auto;
    }
  }
</style>
