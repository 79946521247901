<template>
    <v-container class="wrapper member-list px-4 h-100" ref="team-list">
      <v-row class="h-100 ma-auto">
        <Loader v-if="loading" />
        <ag-grid-vue
        style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
           :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :rowData="sortedItems"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :modules="modules"
          @selection-changed="onSelectionChanged"
          :suppressRowClickSelection=true
          :tooltipShowDelay="1"
          @model-updated="onGridUpdated"
          :pagination="true"
          :paginationPageSize="20"
        >
        </ag-grid-vue>
      </v-row>
    <div
      :ref="'details-popup'"
      v-show="showDetails"
      class="details-block"
    >
      <user-details-card
        :user="selectedMember"
        @closePopup="showDetails = false"
        :showTags="showTags(selectedMember)"
      >
      </user-details-card>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
// import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { AgGridVue } from 'ag-grid-vue';
import { generateUrl } from '@/helpers';
import Loader from '@/components/common/Loader';
import UserDetailsCard from './UserDetailsCard';
import BtnCellRenderer from './BtnCellRenderer';

export default {
  components: {
    AgGridVue,
    UserDetailsCard,
    Loader,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    skillsetIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('project', ['creativeTeam']),
    ...mapGetters('profile', ['skillsets']),
    memberInitials() {
      return (memberName) => {
        let name = memberName;
        name = name.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    items() {
      let { users } = this;
      if (this.skillsetIds.length) {
        users = _.filter(this.users, (user) => {
          if (_.intersection(_.map(_.get(user.creativeProfile, 'skillsets', []), 'id'), this.skillsetIds).length > 0) {
            return user;
          }
          if (this.skillsetIds.includes('Editor')) {
            return _.get(user, 'role.name', []) === 'editor';
          }
          return false;
        });
      }
      return _.map(users, (user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        newTag: user.newTag,
        status: user.status,
        portfolio: this.portfolioUrl(_.get(user.creativeProfile, 'portfolio', '')),
        skillset: this.skillsetNames(_.get(user.creativeProfile, 'skillsets', [])),
        interests: _.get(user.creativeProfile, 'interests', []).map((x) => x.name).join(', '),
        camera: _.get(user.creativeProfile, 'cameraEquipments', []).map((x) => x.name).join(', '),
        lens: _.get(user.creativeProfile, 'lensEquipments', []).map((x) => x.name).join(', '),
        accessories: _.get(user.creativeProfile, 'accessories', []).map((x) => x.name).join(', '),
        copyInterests: _.get(user.creativeProfile, 'writerInterests', []).map((x) => x.name).join(', '),
        styles: _.get(user.creativeProfile, 'styles', []).map((x) => x.name).join(', '),
        subjects: _.get(user.creativeProfile, 'subjects', []).map((x) => x.name).join(', '),
        // eslint-disable-next-line global-require
        profilePic: user.profilePic,
        // eslint-disable-next-line global-require
        portfolioIcon: require('@/assets/svg/theme/open-in-new.svg'),
        projectsCount: user.activeProjectsCount,
      }));
    },
    sortedItems() {
      return _.sortBy(this.items, [(item) => item.name.toLowerCase()], ['asc']);
    },
  },
  methods: {
    ...mapActions('user', ['getCreativesList']),
    skillsetNames(skillsets) {
      return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      const updateData = (data) => {
        this.users = data;
      };
      this.getUsers()
        .then((users) => {
          updateData(users);
        });
      this.gridApi.sizeColumnsToFit();
    },
    onGridUpdated() {
      if (this.gridOptions.api.getDisplayedRowCount()) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },
    onSelectionChanged() {
      this.selected = this.gridApi.getSelectedRows();
    },
    showPopup(e, item) {
      this.showDetails = true;
      this.selectedMember = item;
    },
    resolveUsers(creativesList) {
      const creativesNotInProject = _.filter(creativesList,
        (creative) => !_.some(this.creativeTeam,
          (member) => (member.user.id === creative.id)));
      this.listFilterValues = {
        zipcode: _.chain(creativesNotInProject)
          .flatMap((user) => ({ value: user.zipcode, text: user.zipcode }))
          .uniqBy('value')
          .value(),
      };
      this.creativesNotInProject = creativesNotInProject;
      return creativesNotInProject;
    },
    async getUsers() {
      this.loading = true;
      const { creativesList } = await this.getCreativesList();
      const creatives = creativesList;
      this.creativesList = creativesList;
      const creativesNotInProject = this.resolveUsers(creatives);
      this.loading = false;
      return creativesNotInProject;
    },
    showTags(user) {
      if (user.skillset) {
        const skills = user.skillset.split(',');
        if (skills.length > 1) {
          return true;
        }
        return skills[0] !== 'Graphic Designer';
      }
      return false;
    },
    portfolioUrl(link) {
      return generateUrl(link);
    },
    hideAllPreferences() {
      const columns = ['interests', 'lens', 'camera', 'accessories', 'styles', 'subjects', 'copyInterests'];
      _.each(columns, (column) => {
        this.columnApi.setColumnVisible(column, false);
      });
    },
    async resetColumnVisibility() {
      this.hideAllPreferences();
      const selectedSkills = _.map(_.filter(this.skillsets, (skill) => _.includes(this.skillsetIds, skill.id)), 'name');
      _.each(selectedSkills, (skill) => {
        switch (skill.toLowerCase()) {
          case 'copywriter':
            _.each(['styles', 'subjects', 'copyInterests'], (column) => {
              this.columnApi.setColumnVisible(column, true);
            });
            break;
          case 'photographer':
          case 'videographer':
            _.each(['interests', 'camera', 'lens', 'accessories'], (column) => {
              this.columnApi.setColumnVisible(column, true);
            });
            break;
          default:
            break;
        }
      });
      this.gridApi.sizeColumnsToFit();
    },
    columnDefaultDefs() {
      const nameBlock = (data) => '<div class="name-cell">'
      + `${data.newTag === true && data.status === '1' ? '<div class="v-icon mdi mdi-new-box new-tag"></div>' : '<div class="pl-8"></div>'}`
      + `<div class="item-avatar" style="background-color: #C0C0C0; width: 33px; height: 32px; border-radius: 50%;"> ${data.profilePic ? `<img width=32 height=32 src=${data.profilePic} />` : `<div class="name-initial d-flex align-center justify-center" style="width: inherit; height: inherit; font-size: 16px;"> ${this.memberInitials(data.name)} </div>`} </div> `
      + '<div class="item-content">'
      + `<div class="name">${data.name}`
      + `<a href="${data.portfolio}" target="_blank"><i aria-hidden="true" class="text-purple v-icon notranslate mdi mdi-open-in-new theme--light text--info"></i></a>`
      + `</div><div class="skillset">${data.skillset}</div>`
      + '</div></div>';

      return [
        {
          headerName: 'Name',
          field: 'name',
          checkboxSelection: true,
          filter: true,
          filterParams: ['test', 'tstings'],
          cellRenderer(params) {
            return nameBlock(params.data);
          },
          width: 250,
          resizable: false,
          suppressSizeToFit: true,
          headerClass: 'center-align',
          cellClass: 'name notranslate',
          autoHeight: true,
          suppressMovable: true,
          lockPosition: true,
        },
        {
          headerName: '#Projects',
          field: 'projectsCount',
          headerClass: 'center-align',
          cellStyle: { textAlign: 'center' },
          lockPosition: true,
          resizable: true,
          width: 100,
        },
        {
          headerName: 'Email',
          field: 'email',
          hide: true,
        },
        {
          headerName: 'Interest',
          headerClass: 'left-align',
          field: 'interests',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'interests',
        },
        {
          headerName: 'Camera List',
          headerClass: 'left-align',
          field: 'camera',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'camera',
        },
        {
          headerName: 'Lens List',
          headerClass: 'left-align',
          field: 'lens',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'lens',
        },
        {
          headerName: 'Accessories',
          headerClass: 'left-align',
          field: 'accessories',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'accessories',
        },
        {
          headerName: 'Writer Interest',
          headerClass: 'left-align',
          field: 'copyInterests',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'copyInterests',
        },
        {
          headerName: 'Style',
          headerClass: 'left-align',
          field: 'styles',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'styles',
        },
        {
          headerName: 'Subjects',
          headerClass: 'left-align',
          field: 'subjects',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'subjects',
        },
        {
          headerName: '',
          field: 'id',
          cellRendererFramework: BtnCellRenderer,
          cellRendererParams: {
            icon: 'mdi-chevron-down',
            clicked(params) {
              const { rowHeight } = params.node;
              _.each(params.data, (v) => {
                // if (rowHeight < 18 * (Math.floor(v.length / 45) + 1)) {
                // }
                const cellHeight = 18 * (Math.floor(v.length / 45) + 1);
                if (rowHeight < cellHeight) {
                  params.node.setRowHeight(cellHeight);
                }
              });
            },
          },
          width: 10,
          hide: true,
        },
      ];
    },
  },
  data() {
    return ({
      creativesList: [],
      creativesNotInProject: [],
      users: [],
      selected: [],
      selectedMember: {},
      showDetails: false,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      overlayLoadingTemplate: null,
      modules: [],
      rowData: null,
      loading: false,
    });
  },
  async beforeMount() {
    this.gridOptions = {
      components: {
        BtnCellRenderer,
      },
    };
    this.columnDefs = this.columnDefaultDefs();

    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No users to show</span>';
    this.getRowHeight = (params) => (
      params.api.getSizesForCurrentTheme().rowHeight
        * Math.floor(params.data.name.length / 50)
    );
  },
  watch: {
    search(newVal) {
      this.gridOptions.api.setQuickFilter(newVal);
    },
    selected(newValue, oldValue) {
      if (oldValue.length !== newValue.length) {
        this.$emit('selected-members', newValue);
      }
    },
    skillsetIds() {
      this.resetColumnVisibility();
    },
    creativeTeam(newValue, oldValue) {
      if (oldValue.length !== newValue.length) {
        this.selected = [];
        this.users = this.resolveUsers(this.creativesList);
      }
    },
  },
  beforeDestroy() {
    this.$emit('remove-filters');
  },
};
</script>

<style lang="scss">
.name {
  .ag-selection-checkbox .ag-checkbox .ag-checkbox-input-wrapper input{
    cursor: pointer;
  }
}
  .new-tag {
    color: #f29f33;
    padding-right: 10px;
  }
  .ag-cell {
    &.ag-cell-focus {
      border-color: transparent !important;
    }
    &.name {
      justify-content: left;
      white-space: normal !important
    }
    &.portfolio {
      display: flex;
      justify-content: flex-end;
      padding-right: 35px;
    }
    .name-cell {
      text-align: left;
      display: flex;
      align-items: center;
      .item-avatar {
        img {
          border-radius: 50%;
        }
      }
      .item-content{
        margin-left: 10px;
        .name {
          margin-bottom: 5px;
        }
        .v-icon {
          margin-left: 10px;
          font-size: 18px;
        }
        .skillset {
          line-height: 1.2;
        }
      }
    }
  }
  .member-list {
    border: 1px solid #d0d0d0;
    border-radius: 10px;
  }
</style>
