import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"pa-0":""}},[_c(VRow,{staticClass:"ma-0 heading"},[_c(VCol,{staticClass:"pa-0 d-flex align-center"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$attrs.section.heading))])]),_c(VCol,{staticClass:"d-flex align-center justify-end pa-0"},[(_vm.$attrs.section.value === 'creativeTeam' && _vm.$attrs.accessLevel === 'high')?_c('div',[_c(VBtn,{staticClass:"btn-purple",on:{"click":function($event){return _vm.$emit('add-member')}}},[_vm._v(" Add Member ")])],1):_vm._e(),(_vm.$attrs.section.value === 'addMember')?_c('div',{staticClass:"d-flex"},[_c('base-input',{staticClass:"search-input mr-4",attrs:{"type":"text","solo":"","dense":"","flat":"","hide-details":true,"prepend-inner-icon":"search","placeholder":"Search","clearable":""},on:{"click:clear":function($event){_vm.searchVal === ''}},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c(VSelect,{attrs:{"items":_vm.getSkillsets(),"label":"Skill Set","solo":"","item-value":"id","item-text":"name","hide-details":true,"multiple":"","menu-props":{'content-class' : 'skillset-dropdown'}},on:{"change":function($event){return _vm.$emit('select-skillset', _vm.skillIds)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c(VChip,[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.skillsetIds.length - 1)+" others)")]):_vm._e()]}}],null,false,1147759637),model:{value:(_vm.skillIds),callback:function ($$v) {_vm.skillIds=$$v},expression:"skillIds"}})],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }