<template>
  <span class="d-flex flex-row align-center status">
    <AvatarRound
      :size="41"
      :avatar="params.data.profilePic"
      :name="memberInitials(params.data)"
      @openPopup="btnClickedHandler()"
      textStyle="font-size: 16px;"
    />
    <span class="notranslate" style="margin-left: 8px;">{{ params.value }}</span>
  </span>
</template>

<script>
import Vue from 'vue';
import AvatarRound from '@/components/common/Avatar';

export default Vue.extend({
  name: 'MemberName',
  components: {
    AvatarRound,
  },
  methods: {
    memberInitials(member) {
      let { name } = member;
      name = name.trim();
      if (name) {
        if (name.indexOf(' ') > 0) {
          const first = name.split(' ');
          name = first[0].charAt(0) + first[1].charAt(0);
        } else {
          name = name.charAt(0) + name.charAt(1);
        }
      }
      return name;
    },
    btnClickedHandler() {
      this.params.clicked(this.params);
    },
  },
});
</script>
