import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"team-list",staticClass:"h-100"},[_c('base-list',{attrs:{"headers":_vm.headers,"items":_vm.items,"fetching":_vm.loading}}),_c(VDialog,{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"modal-card pa-4",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"black--text"},[_vm._v(" Deleting a user from Creative Team removes creative from all future sweeps. Click on Confirm to continue. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"py-0",attrs:{"text":"","color":"primaryGray1"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"pa-0 ml-2",attrs:{"text":"","color":"info","type":"submit"},on:{"click":function($event){return _vm.deleteItem(_vm.selectedItem)}}},[_vm._v(" Confirm ")])],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetails),expression:"showDetails"}],ref:'details-popup',staticClass:"details-block"},[_c('user-details-card',{attrs:{"user":_vm.selectedMember,"showTags":_vm.showTags(_vm.selectedMember)},on:{"closePopup":function($event){_vm.showDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }